.chart {
  height: 250px;
}

.small-tip {
  max-width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}
